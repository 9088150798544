import React, { useState, useEffect, useContext } from "react"

import Layout from "../components/layout"
import SEO from '../components/seo'
import Img from "gatsby-image"
import { graphql } from "gatsby"
import {
  Container,

  SwiperContainer,
  Wrapper,
  Header,
  HeaderTitle,
  Like,
  Produktbeschreibung,
  ConfigContainer,
  DetailsLi,






  Right,
 
  CallToActionContainer,


  Price,
 

  GeneralDetailsHeadline,
    GeneralDetailsContainer,
    GeneralDetailsLabel,
    GeneralDetailsWrapper,
    GeneralDetailsUl,
  MoodContainer,
    VisitUsContainer,
  VisitUsHead,
  VisitUsBody,
  ActionContainer
} from "../styles/templates/generalStyles"
import Heart from "../components/heart"
import FilledHeart from "../components/filledHeart"
import { FavContext } from '../context/FavContext'
import FormModal from "../components/form-modal"
import WhatsAppLogo from "../assets/WhatsApp_Logo"
import MailIcon from "../assets/MailIcon"


import SuperSwiper from "../components/Swiper"
import BettenFormular from "../components/utilities/bettenFormular"

const Template = ({ data, props }) => {
  const item = data.allWpBett.nodes[0]

  const { favs, addFav, removeFav, setAdded } = useContext(FavContext)

  const [isFav, setIsFav] = useState(false)



  const itemSlides = item.postTypeBettFields.slides;
  const itemSlidesLf = itemSlides.map(slide => slide.localFile);
  const itemSlideCIS = itemSlidesLf.map(slide => slide.childImageSharp);
  const itemSlidesFluid = itemSlideCIS.map(slide => slide.fluid);
  
  const altText = itemSlides.map(slide => slide.altText);

  const moebel = item.tags.nodes.map(node => node.name)
  const variant = item.ausfuehrungen.nodes.map(node => node.name)

  const itemId = item.id
  const itemSlug = `/betten/${item.slug}`
  const itemName = moebel[0]
  const itemPrice = item.postTypeBettFields.price
  const itemVariant = variant[0]
  const itemImg = itemSlidesFluid[0]

  const farbvarianten = item.postTypeBettFields.farbauswahl

  const legs = item.postTypeBettFields.fussteil

  const masse  = item.postTypeBettFields.masse


  useEffect(() => {
    if (favs.some(fav => fav.id === itemId)) {
      setIsFav(true)
    }
  }, [isFav, favs, itemId])

  const handleAdd = (e) => {
    e.preventDefault();
    addFav(
      itemImg,
      itemName,
      itemVariant,
      itemPrice,
      itemSlug,
      itemId)
      .then(() => {
        setIsFav(true)
        setAdded(true);
      })
  }

  const handleRemove = (e) => {
    e.preventDefault();
    removeFav(itemId)
      .then(() => {
        setIsFav(false)
      })
  }

  return (
    <Layout>
      <SEO title={variant + " " + moebel}></SEO>
      <Container>
        <Wrapper>
          <SwiperContainer>
            <SuperSwiper
              alt={altText}
              slideData={itemSlides}
            />
          </SwiperContainer>
          <Right>
            <Header>
              <div>
                <HeaderTitle>{moebel}</HeaderTitle>
              </div>

          

            </Header>

            <Produktbeschreibung>
              {item.postTypeBettFields.description}
            </Produktbeschreibung>

             <ConfigContainer>
            
              <GeneralDetailsHeadline>Produktdetails</GeneralDetailsHeadline>
              <GeneralDetailsContainer>
                <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Abmessungen</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    {item.postTypeBettFields.dimensions.map(i =>
                      <DetailsLi>
                        {i.dimension}:{i.value}
                                </DetailsLi>
                    )}
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper>

            

                {/* <GeneralDetailsWrapper>
                  <GeneralDetailsLabel>Materialien</GeneralDetailsLabel>
                  <GeneralDetailsUl>
                    {item.postTypeBettFields.materials &&
                      <>

                        {item.postTypeBettFields.materials.map(i =>
                          <DetailsLi>
                            {i.materialbeschreibung}
                          </DetailsLi>
                        )}
                      </>
                    }
                  </GeneralDetailsUl>
                </GeneralDetailsWrapper> */}
             
              </GeneralDetailsContainer>
              
            </ConfigContainer> 




              <Price>Ab {item.postTypeBettFields.price} €</Price>




            <CallToActionContainer>



                <ActionContainer>

                  {isFav === false &&
                    <form onSubmit={handleAdd} >
                      <Like type="submit">
                        <Heart />
                      </Like>
                    </form>
                  }


                  {isFav === true &&
                    <form onSubmit={handleRemove} >
                      <Like type="submit">
                        <FilledHeart />
                      </Like>
                    </form>
                  }

                  <FormModal name={`Unverbindliche Anfrage`}>
                    <BettenFormular
                    moebel={moebel}
                    farbvarianten={farbvarianten}
                    legs={legs}
                    masse={masse}
                    />
                  </FormModal>
                </ActionContainer>
              </CallToActionContainer>
              <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}>
              <span>Haben Sie noch Fragen?</span>
              <div
              style={{
                marginTop: ".5rem",
                display: "flex",
                alignItems: "center"
              }}>
              <button
              style={{
                width: "48px",
                height: "48px",
                border: "none",
                background: "inherit"
              }}>
                <a href="https://api.whatsapp.com/send?phone=4915236611286" title="Senden Sie uns eine Nachricht über WhatsApp!" target="_blank" rel="noreferrer noopener">
                  <WhatsAppLogo />
                </a>
              </button>

            
              <a
              href={`mailto:info@diewohnplaner.de?subject=Bett ${moebel}`}

              style={{
                width: "48px",
                height: "48px",
                border: "none",
                background: "inherit",
                cursor: "pointer"
              }}>
                <MailIcon />
                </a>
            </div>
            </div>
            
          
          
          
          
          
          </Right>









        </Wrapper>

        

        {item.postTypeBettFields.moodimg &&
          <MoodContainer>
            <Img fluid={item.postTypeBettFields.moodimg.localFile.childImageSharp.fluid} />
          </MoodContainer>
        }

<VisitUsContainer>
          <VisitUsHead>Sie haben das Richtige noch nicht gefunden?</VisitUsHead>
          <VisitUsBody>Dann besuchen Sie uns in unserem Geschäft. Wir helfen Ihnen gern dabei, genau das Richtige für Sie zu finden.</VisitUsBody>
        </VisitUsContainer>




      </Container>

      <div type="hidden" style={{ display: 'none' }}>
        <form
          name="Bett-Formular"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="moebel" />
          <input type="hidden" name="farbe" />
          <input type="hidden" name="masse" />
          <input type="hidden" name="fuesse" />
          <input type="hidden" name="nachname" />
          <input type="hidden" name="vorname" />
          <input type="hidden" name="email" />
          <input type="hideen" name="telefonnummer" />
          <input type="hidden" name="wohnort" />
          <button type="submit">Abschicken</button>
        </form>
      </div>


    </Layout>
  )
}

export default Template

export const query = graphql`
query($slug: String!) {
    allWpBett(filter: {slug: {eq: $slug}}) {
        nodes {
      id
      slug
     seo {
      title
     }
     ausfuehrungen {
        nodes {
           name
         }       }
       tags {
         nodes {
           name
         }
       }
      postTypeBettFields {
        price
        saleprice
        description
        slides {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 750, quality: 99, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        dimensions {
          dimension
          value
        }
        materials
        masse {
          mass
        }
        farbauswahl {
          description
          localFile {
            childImageSharp {
              fluid(maxWidth: 250, maxHeight: 250, quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fussteil {
          fuss
        }
        moodimg {
          localFile {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`